<template>
    <div class="d-flex justify-content-between">
        <p class="text-general f-14 px-3 pt-1 fr-light">{{ title }}</p>
        <i class="icon-close text-general f-20 cr-pointer" @click="$emit('remove')" />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '-'
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
