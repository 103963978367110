<template>
    <modal ref="modalEditarCuentaMercadoPago" titulo="Editar cuenta de pasarela de pago" tamano="modal-lg" adicional="Guardar" no-aceptar @adicional="actualizarCuentaMercadoPago">
        <ValidationObserver ref="form" class="w-100">
            <div class="row mx-4 mb-3">
                <div class="col-6 px-4 d-flex flex-column gap-3">
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="nombre">
                            <label for="nombre" class="text-general pl-3 f-12">Nombre de la cuenta</label>
                            <el-input id="nombre" v-model="cuenta.nombre" class="w-100" placeholder="Nombre" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:120" name="public-key">
                            <label for="public-key" class="text-general pl-3 f-12">Public key</label>
                            <el-input id="public-key" v-model="cuenta.public_key" class="w-100" placeholder="public Key" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:120" name="access-token">
                            <label for="access-token" class="text-general pl-3 f-12">Access token</label>
                            <el-input id="access-token" v-model="cuenta.access_token" class="w-100" placeholder="Access token" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:400" name="descripcion">
                            <label for="descripcion" class="text-general pl-3 f-12">Descripción</label>
                            <el-input id="descripcion" v-model="cuenta.descripcion" placeholder="Descripción de la cuenta" type="textarea" :rows="4" class="w-100" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-6 px-4 d-flex flex-column gap-3">
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:400" name="codigo de la empresa">
                            <label for="empresa" class="text-general pl-3 f-12">Código de la empresa</label>
                            <el-input id="empresa" v-model="cuenta.client_id" class="w-100" placeholder="Código de la empresa" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:400" name="client-secret">
                            <label for="client-secret" class="text-general pl-3 f-12">Client secret</label>
                            <el-input id="client-secret" v-model="cuenta.client_secret" class="w-100" placeholder="Client secret" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="moneda">
                            <label for="moneda" class="text-general pl-3 f-12">Moneda</label>
                            <el-select v-model="cuenta.moneda_idm" placeholder="Selecciona" class="select-account w-100" collapse-tags>
                                <el-option
                                v-for="item in monedas"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <label for="cedis" class="text-general pl-3 f-12">Seleccionar cedis</label>
                        <el-select v-model="idCedis" placeholder="Selecciona" class="select-account w-100" multiple collapse-tags>
                            <el-option
                            v-for="item in cedis"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <div class="d-flex flex-column gap-2 mt-2">
                            <template v-for="item, index in cedis">
                                <seleccionado
                                v-if="idCedis.includes(item.id)"
                                :key="index"
                                class="border br-6 bg-light"
                                :title="item.nombre"
                                @remove="deseleccionarCedis(item.id)"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import MercadoPago from '../../../services/mercadoPago/mercadoPago'
import CedisAll from "~/services/cedis";
import seleccionado from '../components/seleccionado.vue'
export default {
    components: {
        seleccionado
    },
    data(){
        return {
            cedis: [],
            idCedis: [],
            cuenta: {
                id: '',
                nombre: '',
                descripcion: '',
                public_key: '',
                access_token: '',
                client_id: '',
                client_secret: '',
                moneda_idm: '',
                cedis: [],
                idCedis: [],
            },
        }
    },
    computed: {
        ...mapGetters({
            monedas:'cedis/monedas',
        }),
    },
    methods: {
        async toggle(cuenta){
            await this.get_cedis();
            this.cuenta = cuenta;
            this.idCedis = cuenta.cedis.map(el => el.cedis_id);

            this.$refs.modalEditarCuentaMercadoPago.toggle();
        },
        async get_cedis(){
            try {
                const {data} = await CedisAll.get_cedis()
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        deseleccionarCedis(id){
            const index = this.idCedis.indexOf(id);
            this.idCedis.splice(index, 1);
        },
        async actualizarCuentaMercadoPago(){
            try {
                const valid = await this.$refs.form.validate();
                if(!valid) return;

                this.cuenta.idCedis = this.idCedis;
                const {data} = await MercadoPago.editarCuenta(this.cuenta.id, this.cuenta);

                this.notificacion('','Cuenta editada correctamante','success');
                this.$emit('update');
                this.$refs.form.reset();
                this.onClose();

                this.$refs.modalEditarCuentaMercadoPago.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        onClose(){
            this.$refs.form.reset();
            this.cuenta.cedis = [];
            this.cuenta.nombre = '';
            this.cuenta.descripcion = '';
            this.cuenta.public_key = '';
            this.cuenta.access_token = '';
            this.cuenta.client_id = '';
            this.cuenta.client_secret = '';
            this.cuenta.moneda_idm = '';
        }
    }
}
</script>

<style lang="scss" scoped>
.select-account{
    .el-input{
        input{
            height: 32px;
        }
    }
}
</style>
