<template>
    <div class="br-10" :class="estadoColor(text)">
        <p class="text-sm-center">{{ estadoPago(text) }}</p>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'N/A'
        },
        color: {
            type: String,
            default: 'text-danger'
        }
    },
    data(){
        return {
            pago: ''
        }
    },
    methods: {
        estadoPago(text){
            switch (text){
            case 'approved':
                return 'Aprobado'
            case 'accredited':
                return 'Aprobado'
            case 'rejected':
                return 'Rechazado'
            case 'in_process':
                return 'En proceso'
            case 'pending':
                return 'En proceso'
            default:
            }
        },
        estadoColor(text){
            switch (text){
            case 'approved':
                return 'text-light estadoColor'
            case 'accredited':
                return 'text-light estadoColor'
            case 'rejected':
                return 'bg-red text-light'
            case 'in_process':
                return 'bg-gris'
            case 'pending':
                return 'bg-gris'
            default:
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.estadoColor{
        background:#28D07B;
    }

</style>
