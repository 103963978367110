<template>
    <modal-lateral ref="modalFiltroPagos" titulo="Filtro">
        <div class="d-flex flex-column justify-content-between h-100 px-4 py-2">
            <div class="d-flex flex-column gap-3">
                <div class="px-1">
                    <label for="fecha-pago" class="d-block text-general pl-3 f-12">Fecha de pago</label>
                    <el-date-picker
                    id="fecha-pago"
                    v-model="model.fechaPago"
                    type="daterange"
                    placeholder="Fecha inicio - Fecha fin"
                    prefix-icon="el-icon-date"
                    popper-class="date-picker-edit-popper"
                    class="w-100"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
                <div class="px-1">
                    <label for="cedis" class="d-block text-general pl-3 f-12">Cedis</label>
                    <el-select v-model="model.cedis" placeholder="Selecciona" class="w-100" multiple>
                        <el-option
                        v-for="item in cedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                    <div class="d-flex flex-column gap-2 mt-2">
                        <template v-for="item, index in cedis">
                            <seleccionado
                            v-if="model.cedis.includes(item.id)"
                            :key="index"
                            class="border-bottom"
                            :title="item.nombre"
                            @remove="deseleccionarCedis(item.id)"
                            />
                        </template>
                    </div>
                </div>
                <div class="px-1">
                    <label for="cedis" class="d-block text-general pl-3 f-12">Medios de pago</label>
                    <el-select v-model="model.mediosPago" placeholder="Selecciona" class="w-100">
                        <el-option
                        v-for="item in mediosPago"
                        :key="item.label"
                        :value="item.value"
                        :label="item.label"
                        />
                    </el-select>
                </div>
                <div class="px-1">
                    <div class="d-flex justify-content-between bg-light br-8 p-2 ps-3 border">
                        <span class="text-general f-14">Estado</span>
                        <div class="bg-general3 text-white rounded-circle w-20px h-20px text-center f-14">
                            1
                        </div>
                    </div>
                    <div class="mt-3 mx-3">
                        <el-checkbox-group v-model="model.estados" class="d-flex flex-column gap-2">
                            <el-checkbox
                            v-for="item, index in estados"
                            :key="index"
                            :label="item.value"
                            class="text-general check-dark f-300"
                            >
                                {{ item.label }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end mb-2">
                <div class="btn-outline f-14 px-3 mr-2 wm-126px" @click="limpiarFiltro">
                    Limpiar
                </div>
                <div class="btn-general f-14 px-3 ml-2 wm-140px" @click="filtrar">
                    Filtrar
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import CedisAll from "~/services/cedis";
import modalLateral from '../../../components/modal/lateral.vue';
import seleccionado from '../components/seleccionado.vue';

export default {
    components: {
        modalLateral,
        seleccionado,
    },
    data(){
        return {
            model: {
                fechaPago: [],
                cedis: [],
                estados: [],
                mediosPago: '',
            },
            cedis: [],
            mediosPago: [
                { label: 'Tarjeta crédito', value: 'credit_card' },
                { label: 'Tranferencia bancaria', value: 'bank_transfer' },
                { label: 'Mercado pago', value: 'account_money' },
            ],
            estados: [
                { label: 'Aprobado', value: 'approved' },
                { label: 'Rechazado', value: 'rejected' },
                { label: 'Pendiente', value: 'in_process' },
            ]
        }
    },
    methods: {
        async toggle(){
            await this.get_cedis();
            this.$refs.modalFiltroPagos.toggle();
        },
        deseleccionarCedis(id){
            const index = this.model.cedis.indexOf(id);
            this.model.cedis.splice(index, 1);
        },
        async get_cedis(){
            try {
                const {data} = await CedisAll.get_cedis()
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarFiltro(){
            this.model.fechaPago = [];
            this.model.cedis = [];
            this.model.estados = [];
            this.model.mediosPago = '';
        },
        filtrar(){
            this.$emit('filtrar', this.model);
            this.$refs.modalFiltroPagos.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
