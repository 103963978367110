import axios from 'axios'

const API = 'mercado-pago'

const MercadoPago = {
    selectCuentas(params){
        return axios.get(`${API}/cuentas/select`)
    },
    getPagosCuenta(idCuenta, params){
        return axios.get(`${API}/${idCuenta}/pagos`,{params})
    },
    getCuentas(params){
        return axios.get(`${API}/cuentas`)
    },
    actualizarCedisEstado(idCuenta, payload){
        return axios.put(`${API}/cuentas/${idCuenta}/cedis/editar-estado`,payload)
    },
    deleteCuenta(idCuenta){
        return axios.delete(`${API}/cuentas/${idCuenta}/eliminar`)
    },
    updateEstadoCuenta(idCuenta, payload){
        return axios.put(`${API}/cuentas/${idCuenta}/editar-estado`,payload)
    },
    crearCuenta(payload){
        return axios.post(`${API}/cuentas`,payload)
    },
    editarCuenta(idCuenta, payload){
        return axios.put(`${API}/cuentas/${idCuenta}/editar`,payload)
    },
    exportarPagos(idCuenta, params){
        return axios.get(`${API}/cuentas/${idCuenta}/exportar-pagos`, {params})
    },
}

export default MercadoPago
