<template>
    <modal ref="modalCrearCuentaMercadoPago" titulo="Crear cuenta de pasarela de pago" tamano="modal-lg" adicional="Crear" cerrar no-cancelar no-aceptar @adicional="crearCuentaMercadoPago" @cerrar="onClose()">
        <ValidationObserver ref="form" class="w-100">
            <div class="row mx-4 mb-3">
                <div class="col-6 px-4 d-flex flex-column gap-3">
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="nombre">
                            <label for="nombre" class="text-general pl-3 f-12">Nombre de la cuenta</label>
                            <el-input id="nombre" v-model="model.nombre" class="w-100" placeholder="Nombre" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:120" name="public-key">
                            <label for="public-key" class="text-general pl-3 f-12">Public key</label>
                            <el-input id="public-key" v-model="model.publicKey" class="w-100" placeholder="public Key" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:120" name="access-token">
                            <label for="access-token" class="text-general pl-3 f-12">Access token</label>
                            <el-input id="access-token" v-model="model.accessToken" class="w-100" placeholder="Access token" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:400" name="descripcion">
                            <label for="descripcion" class="text-general pl-3 f-12">Descripción</label>
                            <el-input id="descripcion" v-model="model.descripcion" placeholder="Descripción de la cuenta" type="textarea" :rows="4" class="w-100" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-6 px-4 d-flex flex-column gap-3">
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:400" name="codigo de la empresa">
                            <label for="empresa" class="text-general pl-3 f-12">Código de la empresa</label>
                            <el-input id="empresa" v-model="model.empresa" class="w-100" placeholder="Código de la empresa" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required|max:400" name="client-secret">
                            <label for="client-secret" class="text-general pl-3 f-12">Client secret</label>
                            <el-input id="client-secret" v-model="model.clientSecret" class="w-100" placeholder="Client secret" />
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="moneda">
                            <label for="moneda" class="text-general pl-3 f-12">Moneda</label>
                            <el-select v-model="model.moneda" placeholder="Selecciona" class="select-account w-100" collapse-tags>
                                <el-option
                                v-for="item in monedas"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div>
                        <label for="cedis" class="text-general pl-3 f-12">Seleccionar cedis</label>
                        <el-select v-model="model.idCedis" placeholder="Selecciona" class="select-account w-100" multiple collapse-tags>
                            <el-option
                            v-for="item in cedis"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <div class="d-flex flex-column gap-2 mt-2">
                            <template v-for="item, index in cedis">
                                <seleccionado
                                v-if="model.idCedis.includes(item.id)"
                                :key="index"
                                class="border br-6 bg-light"
                                :title="item.nombre"
                                @remove="deseleccionarCedis(item.id)"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import MercadoPago from '../../../services/mercadoPago/mercadoPago'
import CedisAll from "~/services/cedis";
import seleccionado from '../components/seleccionado.vue'
export default {
    components: {
        seleccionado
    },
    data(){
        return {
            model: {
                nombre: '',
                descripcion: '',
                publicKey: '',
                accessToken: '',
                empresa: '',
                clientSecret: '',
                moneda: '',
                idCedis: [],
            },
            cedis: [],
        }
    },
    computed: {
        ...mapGetters({
            monedas:'cedis/monedas',
        }),
    },
    methods: {
        async toggle(){
            this.onClose();
            await this.get_cedis();

            this.$refs.modalCrearCuentaMercadoPago.toggle();
        },
        deseleccionarCedis(id){
            const index = this.model.idCedis.indexOf(id);
            this.model.idCedis.splice(index, 1);
        },
        async get_cedis(){
            try {
                const {data} = await CedisAll.get_cedis()
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearCuentaMercadoPago(){
            try {
                const valid = await this.$refs.form.validate();
                if(!valid) return;

                const {data} = await MercadoPago.crearCuenta(this.model);

                this.notificacion('','Cuenta agregada correctamante','success');
                this.$emit('update');
                this.$refs.form.reset();

                this.$refs.modalCrearCuentaMercadoPago.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        onClose(){
            this.$refs.form.reset();
            this.model.idCedis = [];
            this.model.nombre = '';
            this.model.descripcion = '';
            this.model.publicKey = '';
            this.model.accessToken = '';
            this.model.empresa = '';
            this.model.clientSecret = '';
            this.model.moneda = '';
        }
    }
}
</script>

<style lang="scss" scoped>
.select-account{
    .el-input{
        input{
            height: 32px;
        }
    }
}
</style>
