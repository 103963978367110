<template>
    <div>
        <cargando v-if="cargando" />
        <div class="d-middle gap-2 py-3">
            <el-button type="text" class="btn-card">
                <i class="f-14 icon-creditos text-general" />
            </el-button>
            <el-select v-model="account" placeholder="Selecciona" class="select-account w-246px">
                <el-option
                v-for="item in accounts"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
                />
            </el-select>
            <el-tooltip content="Editar cuentas" placement="bottom" effect="light" :show-arrow="false">
                <el-button type="text" class="btn-edit border br-8 bg-white p-0 py-1">
                    <i class="f-20 icon-pencil text-general" @click="irListadoCuentas" />
                </el-button>
            </el-tooltip>
        </div>
        <div class="bg-white shadow-sm" style="border-radius: 8px 8px 0 0;">
            <tabla-general
            :data="pagos.filter(data => !search || data.cliente.toLowerCase().includes(search.toLowerCase()) )"
            class-header="text-general f-16"
            :usar-paginacion="true"
            :mostrar-buscador="false"
            alto="calc(100vh - 380px)"
            >
                <template slot="cabecera-izquierda">
                    <h1 class="text-general f-500 f-18 py-2 pl-4">
                        Pagos realizados
                    </h1>
                </template>
                <template slot="cabecera-derecha">
                    <div v-if="account && pagos.length" class="ml-auto col-auto d-middle">
                        <export-excel
                        class="ml-auto my-lg-2 mx-2"
                        :data="pagosExcel"
                        :fields="json_fields"
                        :before-generate="startDownload"
                        :before-finish="finishDownload"
                        worksheet="pagos"
                        name="pagos.xls"
                        >
                            <el-button v-loading="loading_excel" class="p-1 br-8">
                                <div class="d-middle">
                                    <i class="icon-dowload-arrow f-20 text-general" />
                                    <p class="text-general f-medium f-14">Exportar pagos</p>
                                </div>
                            </el-button>
                        </export-excel>
                        <el-button class="p-1 br-16 position-relative bg-light">
                            <div class="d-middle" @click="abrirFiltroPagos">
                                <i class="icon-filter-outline f-20 text-general" />
                                <p class="text-general f-medium f-14">Filtrar</p>
                                <i class="icon-chevron-down f-20 text-general" />
                            </div>
                            <div class="bg-danger rounded-circle w-6px h-6px position-absolute" style="top: 0; right: 0" />
                        </el-button>
                        <el-input v-model="search" class="input-search br-16 ml-2 w-280px" placeholder="Buscar" />
                    </div>
                </template>
                <template slot="adicionales-izquierda">
                    <el-table-column min-width="120" sortable>
                        <template #header>
                            <span class="pl-3">Pedido</span>
                        </template>
                        <template slot-scope="scope">
                            <p class="pl-3">{{ scope.row.admin_pedido_id }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Nombre" min-width="180" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.cliente }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha del pago" min-width="180" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.fecha_pago | helper-fecha('DD MMMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha de pedido" min-width="180" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.fecha_pedido | helper-fecha('DD MMMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Cedis" min-width="150" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.cedis }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Ref. del pago" min-width="150" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.transaccion_id }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor pagado" min-width="150" sortable>
                        <template slot-scope="scope">
                            <p>$ {{ scope.row.valor_total }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Medio de pago" min-width="170" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.medio_pago }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Estado" min-width="120" sortable>
                        <template slot-scope="scope">
                            <Estado :text="scope.row.estado" />
                        </template>
                    </el-table-column>
                </template>
                <template slot="empty">
                    <img src="/img/ilustraciones/ilu_buscar.svg" class="w-180px" alt="no-search" />
                    <p class="text-muted2 mx-auto f-200 f-14 w-250px mt-3 lh-16">No se encontraron resultados para tu busqueda</p>
                </template>
            </tabla-general>
        </div>
        <modal-filtro ref="modalFiltroPagos" @filtrar="filtrarDatos" />
    </div>
</template>
<script>
import MercadoPago from '../../../services/mercadoPago/mercadoPago'
import Estado from '../components/estado.vue';
import modalFiltro from '../partials/modalFiltro.vue';
import Vue from 'vue'
import excel from 'vue-excel-export'

Vue.use(excel)
export default {
    components: {
        modalFiltro,
        Estado,
    },
    data(){
        return {
            search: '',
            cargando: false,
            account: null,
            accounts: [],
            pagos:[],
            datosFiltro: {
                fechaPago: [],
                cedis: [],
                estados: [],
                mediosPago: '',
            },
            pagosExcel: [],
            loading_excel:false,
            json_fields: {
                'Pedido': 'pedido',
                'Cliente': 'cliente',
                'Fecha de pago': 'fecha_pago',
                'Fecha pedido': 'fecha_pedido',
                'Cedis': 'cedis',
                'Ref.pago': 'ref_pago',
                'Valor pagado': 'valor_pagado',
                'Medio pago': 'medio_pago',
                'Estado': 'estado',
            },
        };
    },
    watch: {
        account(){
            this.listarPagos();
        },
    },
    created(){
        this.getCuentas();
    },
    methods: {
        abrirFiltroPagos(){
            this.$refs.modalFiltroPagos.toggle();
        },
        irListadoCuentas(){
            this.$router.push({ name: 'admin.pasarela-pagos.metodos-pago.mercadopago.cuentas' })
        },
        async getCuentas(){
            try {
                const {data} = await MercadoPago.selectCuentas();
                this.accounts =  data.data;
            } catch (e){
                this.error_catch(e);
            }
        },
        async listarPagos(){
            try {
                const params = {
                    idCedis : this.datosFiltro.cedis,
                    estados : this.datosFiltro.estados,
                    mediosPago : this.datosFiltro.mediosPago,
                    fechaInicio : this.datosFiltro.fechaPago[0],
                    fechaFin : this.datosFiltro.fechaPago[1],
                }

                const {data} = await MercadoPago.getPagosCuenta(this.account, params);
                this.pagos =  data.data;
            }
            catch (error){
                return this.error_catch(error);
            }
            finally {
                this.cargando = false;
            }
        },
        filtrarDatos(datos){
            this.datosFiltro = datos;
            this.listarPagos();
        },
        async startDownload(){
            try {
                this.loading_excel = true

                const params = {
                    idCedis : this.datosFiltro.cedis,
                    estados : this.datosFiltro.estados,
                    mediosPago : this.datosFiltro.mediosPago,
                    fechaInicio : this.datosFiltro.fechaPago[0],
                    fechaFin : this.datosFiltro.fechaPago[1],
                }

                const {data} = await MercadoPago.exportarPagos(this.account, params);

                if (data.data.length){
                    this.pagosExcel = data.data;
                }else{
                    this.pagosExcel = [];
                    this.finishDownload();
                }

            }
            catch (error){
                this.finishDownload();
                return this.error_catch(error);
            }
        },
        finishDownload(){
            this.loading_excel = false
        },
    },
}
</script>

<style lang="scss">
.select-account{
    .el-input{
        input{
            height: 32px;
        }
    }
}
.input-search{
    input.el-input__inner{
        height: 32px;
        border-radius: 16px;
    }
}
</style>
