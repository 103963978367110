<template>
    <modal ref="modalEditarCedis" titulo="Editar CEDIS" tamano="modal-md" no-aceptar no-cancelar>
        <p class="text-black f-16 fr-light px-5 mb-2">CEDIS</p>
        <div class="d-flex flex-column gap-3 px-5 mb-3">
            <div v-for="item, index in cedis" :key="index" class="d-middle gap-3">
                <el-input id="nombre" v-model="item.cedis" class="w-100 input-grey" placeholder="Nombre" />
                <el-popover placement="bottom" effect="light" trigger="hover" :show-arrow="false">
                    <div>
                        <p class="f-12 fr-medium">{{ item.estado ? 'Inactivar' : 'Activar' }}</p>
                        <p class="f-12 fr-light">Se inactivará en la cuenta; {{ cuentaNombre }}</p>
                    </div>
                    <template #reference>
                        <el-switch
                        v-model="item.estado"
                        class="mx-3"
                        active-color="#13ce66"
                        :active-value="1" :inactive-value="0"
                        @change="actualizarCedis(item)"
                        />
                    </template>
                </el-popover>
            </div>
        </div>
    </modal>
</template>

<script>
import MercadoPago from '../../../services/mercadoPago/mercadoPago'
export default {
    data(){
        return {
            cedis: [],
            cuentaNombre: '',
        }
    },
    methods: {
        toggle(cuentaCedis){
            this.cedis = cuentaCedis.cedis;
            this.cuentaNombre = cuentaCedis.nombre;
            this.$refs.modalEditarCedis.toggle();
        },
        async actualizarCedis(cuenta){
            try {
                const payload = {
                    idCuentaCedis: cuenta.id,
                    estado: cuenta.estado,
                };

                const {data} = await MercadoPago.actualizarCedisEstado( cuenta.cuenta_id, payload);
                this.notificacion('', data.message, 'success')
                // this.$refs.modalEditarCedis.toggle();
            } catch (e){
                this.error_catch(e);
            }
        }

    }
}
</script>

<style lang="scss">
.input-grey{
    &.el-input{
        input{
            background: #F6F9FB;
        }
    }
}
</style>
