<template>
    <div class="d-flex">
        <div class="mr-2">
            <i class="icon-left-circle f-22 text-general cr-pointer" @click="irPagosMercadoPago" />
        </div>
        <div class="bg-white shadow-sm w-100" style="border-radius: 8px 8px 0 0;">
            <tabla-general
            :data="cuentas.filter(data => !search || data.nombre.toLowerCase().includes(search.toLowerCase()))"
            class-header="text-general f-16 w-100"
            class-row="text-general f-16"
            :usar-paginacion="true"
            :mostrar-buscador="false"
            alto="calc(100vh - 270px)"
            >
                <template slot="cabecera-izquierda">
                    <div class="d-middle ml-2 my-2">
                        <h1 class="text-general f-18 f-medium m-0 pl-4">
                            Lista de cuentas de Mercado pago
                        </h1>
                    </div>
                </template>
                <template slot="cabecera-derecha">
                    <div class="ml-auto col-auto d-middle my-2">
                        <el-button class="p-1 br-16 position-relative bg-alert text-white px-3 py-2 f-14 br-8 f-400 shadow-sm" @click="abrirCrearCuentaMercadoPago">
                            Añadir cuenta
                        </el-button>
                        <el-input v-model="search" class="input-search br-16 w-280px mx-3" placeholder="Buscar" />
                    </div>
                </template>
                <template slot="adicionales-izquierda">
                    <el-table-column label="Nombre" min-width="220" sortable>
                        <template #header>
                            <span class="pl-4">Nombre</span>
                        </template>
                        <template slot-scope="scope">
                            <p class="pl-4">{{ scope.row.nombre }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Cedis" min-width="120" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.cedis.length }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Descripción" min-width="650" sortable>
                        <template slot-scope="scope">
                            <p>{{ scope.row.descripcion }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="210">
                        <template slot-scope="scope">
                            <div class="d-middle">
                                <el-tooltip :content="scope.row.estado ? 'Inactivar' : 'Activar'" placement="bottom" effect="light" :show-arrow="false">
                                    <el-switch v-model="scope.row.estado" :active-value="1" :inactive-value="0" class="mx-3" active-color="#13ce66" @change="updateEstadoCuenta(scope.row)" />
                                </el-tooltip>
                                <el-tooltip content="Eliminar cuenta" placement="bottom" effect="light" :show-arrow="false">
                                    <el-button type="text" class="btn-edit border br-8 bg-white p-0 py-1" @click="abrirModalEliminar(scope.row.id)">
                                        <i class="f-20 icon-delete-outline text-general" />
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content="Editar CEDIS" placement="bottom" effect="light" :show-arrow="false">
                                    <el-button type="text" class="btn-edit border br-8 bg-white p-0 py-1" @click="abrirEditarCedis(scope.row)">
                                        <i class="f-20 icon-cedis-small text-general" />
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content="Editar cuenta" placement="bottom" effect="light" :show-arrow="false">
                                    <el-button type="text" class="btn-edit border br-8 bg-white p-0 py-1" @click="abrirEditarCuentaMercadoPago(scope.row)">
                                        <i class="f-20 icon-pencil text-general" />
                                    </el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </template>
                <template slot="empty">
                    <img src="/img/ilustraciones/ilu_buscar.svg" class="w-180px" alt="no-search" />
                    <p class="text-muted2 mx-auto f-200 f-14 w-250px mt-3 lh-16">No se encontraron resultados para tu busqueda</p>
                </template>
            </tabla-general>
        </div>
        <modal ref="modalEliminar" titulo="Eliminar cuenta" adicional="Eliminar" no-aceptar @adicional="eliminarCuenta">
            <p class="text-general fr-light f-15 text-center">¿Desea eliminar esta cuenta?</p>
        </modal>
        <modal-crear-cuenta-mercado-pago ref="modalCrearCuentaMercadoPago" @update="getCuentasMercadoPago" />
        <modal-editar-cuenta-mercado-pago ref="modalEditarCuentaMercadoPago" @update="getCuentasMercadoPago" />
        <modal-editar-cedis ref="modalEditarCedis" />
    </div>
</template>
<script>
import MercadoPago from '../../../services/mercadoPago/mercadoPago'
import modalCrearCuentaMercadoPago from '../partials/modalCrearCuentaMercadoPago.vue';
import modalEditarCuentaMercadoPago from '../partials/modalEditarCuentaMercadoPago.vue';
import modalEditarCedis from '../partials/modalEditarCedis.vue';

export default {
    components: {
        modalCrearCuentaMercadoPago,
        modalEditarCuentaMercadoPago,
        modalEditarCedis
    },
    data(){
        return {
            search: '',
            cuentas: [],
            idCuenta: null
        }
    },
    created(){
        this.getCuentasMercadoPago();
    },
    methods: {
        abrirModalEliminar(idCuenta){
            this.idCuenta = idCuenta;
            this.$refs.modalEliminar.toggle();
        },
        abrirCrearCuentaMercadoPago(){
            this.$refs.modalCrearCuentaMercadoPago.toggle();
        },
        abrirEditarCuentaMercadoPago(cuenta){
            this.$refs.modalEditarCuentaMercadoPago.toggle(cuenta);
        },
        abrirEditarCedis(cuentaCedis){
            this.$refs.modalEditarCedis.toggle(cuentaCedis);
        },
        irPagosMercadoPago(){
            this.$router.push({ name: 'admin.pasarela-pagos.metodos-pago.mercadopago'})
        },
        async eliminarCuenta(){
            try {
                const {data} = await MercadoPago.deleteCuenta(this.idCuenta);

                if (!data.data) return this.notificacion('Mensaje', data.message, 'warning');

                const filter = this.cuentas.filter((item) => item.id != this.idCuenta)
                this.cuentas = filter;
                this.idCuenta = null;
                this.notificacion('', data.message, 'success')
                this.$refs.modalEliminar.toggle();
            } catch (e){
                this.error_catch(e);
            }
        },
        async getCuentasMercadoPago(){
            try {
                const {data} = await MercadoPago.getCuentas();
                this.cuentas =  data.data;
            } catch (e){
                this.error_catch(e);
            }
        },
        async updateEstadoCuenta(cuenta){
            try {
                const payload = {
                    estado: cuenta.estado
                }
                console.log("🚀 ~ file: cuentas.vue:151 ~ updateEstadoCuenta ~ cuenta:", cuenta)
                const {data} = await MercadoPago.updateEstadoCuenta(cuenta.id, payload);

                this.notificacion('', data.message, 'success')
            } catch (e){
                this.error_catch(e);
            }
        },
    }
}
</script>

<style lang="scss">
.input-search{
    input.el-input__inner{
        height: 32px;
        border-radius: 16px;
    }
}
</style>
